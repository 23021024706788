import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

import { useScript } from './useScript'

export const PAYPAL_LIBRARY_URL =
  'https://www.paypal.com/sdk/js?components=messages&client-id={PAYPAL_CLIENT_ID}'

export const PAYPAL_CLIENT_ID_PLACEHOLDER = '{PAYPAL_CLIENT_ID}'

function getPaypalLibraryUrl(paypalPaymentMethod: PaymentMethod) {
  if (!('paypalClientId' in paypalPaymentMethod.config)) {
    throw new Error('Missing paypalClientId in PayPal payment method config')
  }

  const { paypalClientId } = paypalPaymentMethod.config

  return PAYPAL_LIBRARY_URL.replace(
    PAYPAL_CLIENT_ID_PLACEHOLDER,
    paypalClientId,
  )
}

export const usePaypalLibrary = (paymentMethod: PaymentMethod) => {
  return useScript(getPaypalLibraryUrl(paymentMethod), {
    use() {
      return window.paypal
    },
  })
}
