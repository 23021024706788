import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'

export type PaymentFormVersion = 'v1' | 'v1.1' | 'v1.2'

// TODO: [PAYIN-4199] Remove when the AB test is over
export const usePaymentFormVersion = () => {
  const paymentFormVersionExperiment =
    useExperiments()['experiment.paymentFormVersion']

  switch (paymentFormVersionExperiment) {
    case 'noVariation':
      return 'v1'
    default:
      return paymentFormVersionExperiment as PaymentFormVersion
  }
}
